import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import LoadingScreen from './Component/LoadingScreen';
import 'react-notifications/lib/notifications.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <React.StrictMode>
        <App></App>
      </React.StrictMode>

    </BrowserRouter>
  </RecoilRoot>
);

