import React, { useState } from "react";

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import { firebaseApp } from "../../Config/FirebaseConfig";
import Login from "../../Assets/login.jpg";
import Logo from "../../Assets/newlogo.png";

import { useLocation, useNavigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { AiOutlineHome } from "react-icons/ai";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
const auth = getAuth(firebaseApp);

function LoginPage() {
  const location = useLocation();
  let [authLoad, setAuthLoad] = useState(false);
  let [mail, setMail] = useState("");
  let [password, setPassword] = useState("");
  const veri = location.state?.topage || null;
  let navigate = useNavigate();
  const [value, loading, error] = useCollection(
    collection(getFirestore(firebaseApp), "USERS"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const backgroundStyle = {
    //backgroundImage: `url(${Login})`,
    color:"#ffffff",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const mailHandle = (e) => {
    setMail(e.target.value);
  };

  const passHandle = (e) => {
    setPassword(e.target.value);
  };
  // const [user, loading, error] = useAuthState(auth);
  const login = async () => {
    try {
      let authDurum = await signInWithEmailAndPassword(auth, mail, password);

      if (authDurum.user != null) {
        await userAuthentication(authDurum.user.uid);
        setAuthLoad(false);
      }
    } catch (e) {
      setAuthLoad(false);
      NotificationManager.error(
        "Lütfen eposta ve şifreyi doğru giriniz.",
        "Yanlış",
        2000,
        () => {
          alert("Lütfen eposta ve şifreyi doğru giriniz.");
        }
      );
    }
  };
  const userAuthentication = async (userId) => {
    if (value != null) {
      const documentRef = doc(getFirestore(firebaseApp), "USERS", userId);
      // Doküman var mı yok mu kontrol edin
      getDoc(documentRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            if (veri != null) {
              navigate(veri);
            } else {
              navigate("/");
            }
          } else {
            logout();
            NotificationManager.error(
              "Lütfen eposta ve şifreyi doğru giriniz.",
              "Yanlış",
              2000,
              () => {
                alert("Lütfen eposta ve şifreyi doğru giriniz.");
              }
            );
          }
        })
        .catch((error) => {
          console.error("Doküman kontrolü sırasında hata:", error);
        });
    }
  };

  const logout = () => {
    signOut(auth);
  };

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
    <div className="relative hidden sm:block" style={backgroundStyle}>
      <div className="absolute top-0 left-0 w-full h-full bg-blue bg-opacity-50 backdrop-filter backdrop-blur-lg flex flex-col items-center justify-center">
        <img src={Logo} alt="logo" className="w-60" />
  
        <div className="my-10 border-t h-px w-96"></div>
        <h1 className=" text-3xl mx-16 text-center font-extralight text-black">
          Parça üretimimiz, projelerin başarılı bir şekilde
          gerçekleştirilmesine giden yolunuzdur.
        </h1>
      </div>
    </div>
  
    <div className="bg-gray-200 flex flex-col justify-center">
      <div className="max-w-[400px] w-full mx-auto rounded-lg bg-[#0e2c66] p-8 px-8">
        <h2 className="text-4xl text-white font-bold text-center">Giriş</h2>
        <div className="flex flex-col text-gray-400 py-2">
          <label>Eposta</label>
          <input
            type="email"
            value={mail}
            onChange={mailHandle}
            className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          />
        </div>
        <div className="flex flex-col text-gray-400 py-2">
          <label>Şifre</label>
          <input
            type="password"
            value={password}
            onChange={passHandle}
            className="p-2 rounded-lg bg-gray-700 mt-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          />
        </div>
  
        <div className="flex justify-between text-gray-400 py-2">
          <p className="flex items-center">
            <input className="mr-2" type="checkbox" /> Beni Hatırla
          </p>
          <p>Şifremi unuttum</p>
        </div>
        {authLoad ? (
          <div className="relative w-5 h-5 mx-auto py-4">
            <div
              className="absolute top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
              style={{
                borderTop: "2px solid transparent",
                borderBottom: "2px solid transparent",
              }}
            ></div>
          </div>
        ) : (
          <button
            onClick={async () => {
              setAuthLoad(true);
              login();
            }}
            className="w-full my-5 py-2 bg-[#f5640c] shadow-lg shadow-orange-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
          >
            Giriş yap
          </button>
        )}
  
        <div className="text-center w-full">
          <button
            onClick={() => {
              navigate("/kayit");
            }}
            className="text-gray-400 mt-5"
          >
            Hesap Oluştur
          </button>
        </div>
      </div>
    </div>
    <NotificationContainer />
    <button
      className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-[#0e2c66] text-white rounded-full shadow-lg flex items-center justify-center"
      onClick={() => {
        navigate("/");
      }}
    >
      <AiOutlineHome></AiOutlineHome>
    </button>
  </div>
  
  );
}

export default LoginPage;
