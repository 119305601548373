import React, { useState } from "react";
import Logo from "../../Assets/newlogo.png";
import { getAuth } from "firebase/auth";
import { fireDB, firebaseApp, strogeDb } from "../../Config/FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  AiOutlineCloudUpload,
  AiOutlineHome,
  AiFillFileAdd,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FcAddImage } from "react-icons/fc";
import LoadingScreen from "../LoadingScreen";
import axios from "axios";

const auth = getAuth(firebaseApp);
function KaliteKontrol() {
  const [user, loading, error] = useAuthState(auth);
  let [adet, setAdet] = useState(1);

  let navigate = useNavigate();

  let [resim1, setResim1] = useState(null);
  let [resim2, setResim2] = useState(null);
  let [resim3, setResim3] = useState(null);
  let [resim4, setResim4] = useState(null);

  let [teknikRef, setTeknikRef] = useState(null);
  let [cadDataRef, setCadDataRef] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  let [eni, setEni] = useState("0");

  let [boyu, setBoyu] = useState("0");

  let [yukseklik, setYukselik] = useState("0");

  let [uploadLoading, setUploadLoading] = useState(null);

  const resimOneHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim1(selectedFile);
  };

  const resimTwoHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim2(selectedFile);
  };

  const resimThreeHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim3(selectedFile);
  };

  const resimForHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim4(selectedFile);
  };

  const teknikHandle = async (e) => {
    let file = e.target.files[0];
    setTeknikRef(file);
  };

  const cadDataHandle = async (e) => {
    let file = e.target.files[0];
    setCadDataRef(file);
  };
  const handleChange = (event) => {
    const inputQuantity = parseInt(event.target.value);
    if (!isNaN(inputQuantity)) {
      setAdet(Math.max(1, inputQuantity));
    }
  };

  const KaliteKontrolEkle = async () => {
    try {
      let teknikResimLink = null;
      let cadDataLink = null;
      if (user != null) {
        if (resim1 != null && resim2 != null && resim3 != null && resim4 != null) {
          setUploadLoading(true);
          let userId = user.uid;
          const yeniMesaj = doc(fireDB, "USERS", userId);
          const itemDocRef = collection(
            doc(collection(fireDB, "USERS"), userId),
            "kaliteKontrol"
          );
          const userCreden = await getDoc(yeniMesaj);
          let userData = userCreden.data();
  

          const uploadFile = async (file, path) => {
            const storageRef = ref(strogeDb, path);
            const uploadTask = uploadBytesResumable(storageRef, file);
  
            return new Promise((resolve, reject) => {
              uploadTask.on(
                'state_changed',
                (snapshot) => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setUploadProgress(progress); 
                  console.log(`Yükleme ilerlemesi: ${progress}%`);
                },
                (error) => {
                  reject(error);
                },
                async () => {
                  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                  resolve(downloadURL);
                }
              );
            });
          };
  
          const resim1Link = await uploadFile(resim1, `${userId}/kaliteKontrol/${uuidv4()}`);
  
          const resim2Link = await uploadFile(resim2, `${userId}/kaliteKontrol/${uuidv4()}`);

          const resim3Link = await uploadFile(resim3, `${userId}/kaliteKontrol/${uuidv4()}`);
  
   
          const resim4Link = await uploadFile(resim4, `${userId}/kaliteKontrol/${uuidv4()}`);
  
          if (teknikRef != null) {
            teknikResimLink = await uploadFile(teknikRef, `${userId}/kaliteKontrol/${uuidv4()}`);
          }
  
         
          if (cadDataRef != null) {
            let cadFileName = cadDataRef.name; 
            let cadFileExtension = cadFileName.split('.').pop(); 
  
           
            cadDataLink = await uploadFile(cadDataRef, `${userId}/kaliteKontrol/${uuidv4()}.${cadFileExtension}`);
          }
  
          // Firebase'e veri ekleme
          await updateDoc(yeniMesaj, { mesaj: true });
          await addDoc(itemDocRef, {
            boyutBilgisi: `${eni} x ${boyu} x ${yukseklik} mm`,
            dosyaLink: [resim1Link, resim2Link, resim3Link, resim4Link],
            teknikResim: teknikResimLink != null ? teknikResimLink : "bos",
            cadData: cadDataLink != null ? cadDataLink : "bos",
            cadDataIsim: cadDataRef != null ? cadDataRef.name : "bos",
            teknikResimIsim: teknikRef != null ? teknikRef.name : "bos",
            olculecekParcaSayisi: adet,
            incelenmeDurumu: false,
            zaman: Date.now(),
          });
  
          // Mail gönderim işlemi
          const cadFileSizeInMB = cadDataRef.size / (1024 * 1024); 
          let mailfile = [
            { filename: resim1.name, fileUrl: resim1Link },
            { filename: resim2.name, fileUrl: resim2Link },
            { filename: resim3.name, fileUrl: resim3Link },
            { filename: resim4.name, fileUrl: resim4Link },
          ];
          let cadLinkForMail = "";
  
          if (cadFileSizeInMB > 10) {
            let cadFileName = cadDataRef.name; 
            cadLinkForMail = `
              <h2>CAD Dosyasi:</h2>
              <p><a href="${cadDataLink}" target="_blank" download="${cadFileName}">CAD Dosyasını İndir</a></p>
            `;
          } else {
            mailfile.push({
              filename: cadDataRef.name,
              fileUrl: cadDataLink,  
            });
          }

          if (teknikResimLink != null) {
            mailfile.push({ filename: teknikRef.name, fileUrl: teknikResimLink });
          }
  
          let now = Date.now();
          const mailResponse = await axios.post("https://apitest.calybre3d.com/index.php", {
            to: "mustafab@orsaproje.com",
            headerdata: `${userData["isim"]} - Kalite Kontrol`,
            bodymessage: `
              <!DOCTYPE html>
      <html lang="tr">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
          }
          .container {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .header {
            text-align: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #dddddd;
          }
          .header h1 {
            color: #0e2c66;
            font-size: 24px;
            margin: 0;
          }
          .content {
            padding: 20px 0;
          }
          .content h2 {
            font-size: 18px;
            color: #333333;
          }
          .content p {
            margin: 10px 0;
            font-size: 16px;
            color: #555555;
          }
          .footer {
            text-align: center;
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #dddddd;
            font-size: 14px;
            color: #888888;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>Talep Detaylari</h1>
          </div>
      
          <div class="content">
      
            <h2>Musteri Bilgileri:</h2>
            <p><strong>Isim:</strong> ${userData["isim"]}</p>
            <p><strong>Soyisim:</strong> ${userData["soyisim"]}</p>
            <p><strong>Firma:</strong> ${userData["firma"]}</p>
            <p><strong>E-posta:</strong> ${userData["eposta"]}</p>
      
            <h2>Boyut Bilgisi</h2>
            <p>${eni} x ${boyu} x ${yukseklik} mm</p>
      
            <h2>Olculecek Parca Sayisi</h2>
            <p>${adet}</p>
      
            ${cadLinkForMail}
      
            <h2>Siparis Verilis Zamani</h2>
            <p>${ Date(now).toLocaleString()}</p>
          </div>
      
          <div class="footer">
            <p>Bu e-posta otomatik olarak olusturulmustur. Lutfen cevaplamayiniz.</p>
          </div>
        </div>
      </body>
      </html>
              `,
            mailfile: mailfile,
          });
  
          NotificationManager.success(
            "En kısa sürede dönüş yapılacaktır.",
            "Ürün Eklendi",
            2000
          );
  
          setTimeout(() => {
            setUploadLoading(false);
            navigate("/");
          }, 3000);
          setResim1(null);
          setResim2(null);
          setResim3(null);
          setResim4(null);
          setEni(0);
          setBoyu(0);
          setYukselik(0);
        } else {
          NotificationManager.info(
            "Lütfen 4 adet resim ekleyiniz.",
            "Eksik resim",
            2000
          );
        }
      } else {
        console.log("User Not Login");
      }
    } catch (e) {
      console.error("Hata oluştu:", e);
      NotificationManager.error("Lütfen Tekrar Deneyiniz", "Hata Oluştu", 2000);
      setUploadLoading(false);
    }
  };
  
  
  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

if(uploadLoading){
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="bg-white shadow-lg rounded-lg p-6 w-96">
        <h2 className="text-xl font-semibold text-center mb-4">Dosyalar Yükleniyor...</h2>
        
        <div className="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="absolute left-0 top-0 h-full bg-blue-500 transition-all duration-500"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
        
        <p className="text-center text-gray-700 mt-4">
          Yükleme İlerleme: <span className="font-semibold">{Math.round(uploadProgress)}%</span>
        </p>
      </div>
    </div>
  );

}else{
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <div className="flex items-center justify-start mb-6">
          <img src={Logo} alt="Logo" className="w-28 h-10 mr-2" />
 
        </div>
        <h2 className="text-2xl font-semibold mb-4">Kalite Kontrol</h2>
        <p className="font-thin">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>

        <label
          htmlFor="name"
          className="block font-medium text-gray-700 mb-2 mt-8"
        >
          Ölçülecek Parça Adet Sayısı:
        </label>
        <div className="flex items-center">
          <input
            value={adet}
            onChange={handleChange}
            type="number"
            pattern="[0-9]*"
            className=" w-20 text-sm py-1 text-center border border-blue-500"
            min="1"
          />
        </div>
        <div className="mb-4 mt-5">
          <label
            htmlFor="file"
            className="block font-medium text-gray-700 mb-2"
          >
            Teknik Resim:{" "}
            <span className="text-opacity-40 text-sm text-black">
              (jpeg, pdf, dwg, dxf)
            </span>
          </label>
          <div className="flex items-center">
            <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              <div className="flex flex-col items-center justify-center pt-7">
                {teknikRef === null ? (
                  <>
                    <AiOutlineCloudUpload className="w-12 h-12"></AiOutlineCloudUpload>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Dosya seç
                    </p>
                  </>
                ) : (
                  <>
                    <AiFillFileAdd className="w-12 h-12"></AiFillFileAdd>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {teknikRef.name}
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                className="opacity-0"
                onChange={teknikHandle}
              />
            </label>
          </div>
        </div>

        <div className="mb-4 mt-5">
          <label
            htmlFor="file"
            className="block font-medium text-gray-700 mb-2"
          >
            Cad Data:{" "}
            <span className="text-opacity-40 text-sm text-black">
              (stl, step)
            </span>
          </label>
          <div className="flex items-center">
            <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              <div className="flex flex-col items-center justify-center pt-7">
                {cadDataRef === null ? (
                  <>
                    <AiOutlineCloudUpload className="w-12 h-12"></AiOutlineCloudUpload>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Dosya seç
                    </p>
                  </>
                ) : (
                  <>
                    <AiFillFileAdd className="w-12 h-12"></AiFillFileAdd>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {cadDataRef.name}
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                className="opacity-0"
                 accept=".stl, .step"
                onChange={cadDataHandle}
              />
            </label>
          </div>
        </div>

        <div className="mb-4 ">
          <label
            htmlFor="email"
            className="block font-medium text-gray-700 mb-2"
          >
            Ürün Resmi:
          </label>
          <div className="flex justify-evenly mt-10">
            <div
              className={`${
                resim1 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim1 ? (
                <img
                  src={URL.createObjectURL(resim1)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="1" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="1"
                accept="image/*"
                className="hidden"
                onChange={resimOneHandle}
              />
            </div>

            <div
              className={`${
                resim2 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim2 ? (
                <img
                  src={URL.createObjectURL(resim2)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="2" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="2"
                accept="image/*"
                className="hidden"
                onChange={resimTwoHandle}
              />
            </div>

            <div
              className={`${
                resim3 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim3 ? (
                <img
                  src={URL.createObjectURL(resim3)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="3" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="3"
                accept="image/*"
                className="hidden"
                onChange={resimThreeHandle}
              />
            </div>

            <div
              className={`${
                resim4 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim4 ? (
                <img
                  src={URL.createObjectURL(resim4)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="4" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="4"
                accept="image/*"
                className="hidden"
                onChange={resimForHandle}
              />
            </div>
          </div>
        </div>

        {uploadLoading ? (
          <div className="flex mx-auto w-full mt-10 items-center justify-center ">
            <div
              className="  top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
              style={{
                borderTop: "2px solid transparent",
                borderBottom: "2px solid transparent",
              }}
            ></div>
          </div>
        ) : (
          <div className=" mt-10 mb-5 flex flex-row justify-center">
            <button
              onClick={() => KaliteKontrolEkle()}
              className="px-12 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
            >
              Gönder
            </button>
          </div>
        )}
      </div>

      <button
        className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center"
        onClick={() => {
          // FAB'a tıklandığında yapılacak işlemi buraya ekleyebilirsiniz
          navigate("/");
        }}
      >
        <AiOutlineHome></AiOutlineHome>
      </button>
      <NotificationContainer />
    </div>
  );
}
}

export default KaliteKontrol;
