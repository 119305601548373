import React, { useState } from "react";
import {
  AiOutlineCloudUpload,
  AiOutlineHome,
  AiFillFileAdd,
  AiOutlineArrowLeft,
} from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import { fireDB, firebaseApp, strogeDb } from "../../Config/FirebaseConfig";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import Logo from "../../Assets/newlogo.png";
import { v4 as uuidv4 } from "uuid";
import { StlViewer } from "react-stl-viewer";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoadingScreen from "../LoadingScreen";
import axios from "axios";

const auth = getAuth(firebaseApp);

const Prototipleme = () => {
  let navigate = useNavigate();

  let [basim, setBasim] = useState(0);
  let [asama, setAsama] = useState(0);
  let [stlYukseklik, stlSetYukseklik] = useState("");
  let [stlX, setStlX] = useState("");
  let [stlY, setStlY] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  let [fileRef, setFileRef] = useState(null);
  let [uploadLoading, setUploadLoading] = useState(false);
  let [adet, setAdet] = useState(1);

  const [user, loading, error] = useAuthState(auth);
  const [malzemeSecimi, setMalzemeSecimi] = useState(0);
  const [stlFile, setStlFile] = useState(null);
  const basimTeknolojisi = [
    { id: 0, label: "FDM" },
    { id: 1, label: "SLA" },
  ];
  const handleRadioChange = (itemId) => {
    setMalzemeSecimi(itemId);
  };

  const items = [
    { id: 0, label: "PLA" },
    { id: 1, label: "ABS" },
    { id: 2, label: "PETG" },
    { id: 3, label: "TPU" },
    { id: 4, label: "NYLON" },
    { id: 5, label: "Polikarbonat" },
    { id: 6, label: "PVA" },
  ];
  const basimHandle = (itemId) => {
    setBasim(itemId);
  };

  const handleChange = (event) => {
    const inputQuantity = parseInt(event.target.value);
    if (!isNaN(inputQuantity)) {
      setAdet(Math.max(1, inputQuantity));
    }
  };
  const onStlLoaded = (model) => {
    stlSetYukseklik(Math.trunc(model.height));
    setStlX(Math.trunc(model.width));
    setStlY(Math.trunc(model.length));
  };

  async function prototipHizmetEkle() {
    if (user !== null) {
      setUploadLoading(true);
      let userId = user.uid;
      const yeniMesaj = doc(fireDB, "USERS", userId);
      const itemDocRef = collection(
        doc(collection(fireDB, "USERS"), userId),
        "prototipHizmeti"
      );
    
      const userCreden = await getDoc(yeniMesaj);
      let userData = userCreden.data();
      let fileName = fileRef.name; 
      let fileExtension = fileName.split('.').pop(); 
      let fileId = uuidv4(); 
      

      const storageRef = ref(strogeDb, `${userId}/prototipleme/${fileId}.${fileExtension}`);
    
      try {
     
        const uploadTask = uploadBytesResumable(storageRef, fileRef);
        
       
        uploadTask.on(
          'state_changed',
          (snapshot) => {
          
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      
            setUploadProgress(progress); 
            
            switch (snapshot.state) {
              case 'paused':
                break;
              case 'running':
                break;
              default:
                break;
            }
          },
          (error) => {
        
            console.error("Hata oluştu:", error);
            setUploadLoading(false);

          },
          async () => {
      
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
   
            await updateDoc(yeniMesaj, { mesaj: true });
            await addDoc(itemDocRef, {
              malzemeCesidi: items[malzemeSecimi].label,
              malzemeAdet: adet,
              baskiTeknolojisi: basimTeknolojisi[basim].label,
              boyutBilgisi: `${stlX} x ${stlY} x ${stlYukseklik} mm`,
              dosyaLink: downloadURL,
              dosyaIsmi: fileName, 
              incelenmeDurumu: false,
              zaman: Date.now(),
            });
    
       
            let mailfile = [];
            let fileLinkForMail = "";
            const fileSizeInMB = fileRef.size / (1024 * 1024); 
    
          
            if (fileSizeInMB > 5) {
              fileLinkForMail = `<h2>STL Dosyasi:</h2><p><a href="${downloadURL}">${fileName}</a></p>`;
            } else {
              mailfile.push({
                filename: fileName, 
                fileUrl: downloadURL,  
              });
            }
    
            let now = Date.now();
    
            try {
              const mailResponse = await axios.post("https://apitest.calybre3d.com/index.php", {
                to: "mustafab@orsaproje.com",
                headerdata: `${userData["isim"]} - Prototipleme Hizmeti`,
                bodymessage: `
              <!DOCTYPE html>
              <html lang="tr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    background-color: #f4f4f4;
                    margin: 0;
                    padding: 0;
                  }
                  .container {
                    max-width: 600px;
                    margin: 20px auto;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                  }
                  .header {
                    text-align: center;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #dddddd;
                  }
                  .header h1 {
                    color: #0e2c66;
                    font-size: 24px;
                    margin: 0;
                  }
                  .content {
                    padding: 20px 0;
                  }
                  .content h2 {
                    font-size: 18px;
                    color: #333333;
                  }
                  .content p {
                    margin: 10px 0;
                    font-size: 16px;
                    color: #555555;
                  }
                  .footer {
                    text-align: center;
                    margin-top: 30px;
                    padding-top: 20px;
                    border-top: 1px solid #dddddd;
                    font-size: 14px;
                    color: #888888;
                  }
                </style>
              </head>
              <body>
                <div class="container">
                  <div class="header">
                    <h1>Prototipleme Hizmeti Talep Detaylari</h1>
                  </div>
                  <div class="content">
                    <h2>Musteri Bilgileri:</h2>
                    <p><strong>Isim:</strong> ${userData["isim"]}</p>
                    <p><strong>Soyisim:</strong> ${userData["soyisim"]}</p>
                    <p><strong>Firma:</strong> ${userData["firma"]}</p>
                    <p><strong>E-posta:</strong> ${userData["eposta"]}</p>
                    <h2>Malzeme Cesidi:</h2>
                    <p>${items[malzemeSecimi].label}</p>
                    <h2>Adet:</h2>
                    <p>${adet}</p>
                    <h2>Baski Teknolojisi:</h2>
                    <p>${basimTeknolojisi[basim].label}</p>
                    <h2>Boyut Bilgisi:</h2>
                    <p>${stlX} x ${stlY} x ${stlYukseklik} mm</p>
                    ${fileLinkForMail}
                    <h2>Siparis Verilis Zamani:</h2>
                    <p>${ Date(now).toLocaleString()}</p>
                  </div>
                  <div class="footer">
                    <p>Bu e-posta otomatik olarak olusturulmustur. Lutfen cevaplamayiniz.</p>
                  </div>
                </div>
              </body>
              </html>
                `,
                mailfile: mailfile,
              });
        
              console.log("Mail başarıyla gönderildi:", mailResponse.data);
            } catch (mailError) {
              console.error("Mail gönderim hatası:", mailError);
              NotificationManager.error("Mail gönderiminde hata oluştu", "Hata", 2000);
            }
        
            NotificationManager.success(
              "En kısa sürede dönüş yapılacaktır.",
              "Ürün Eklendi",
              2000
            );
        
            setFileRef(null);
        
            setTimeout(() => {
              setUploadLoading(false);
              navigate("/");
            }, 3000);
          }
        );
      } catch (error) {
        console.error("Hata oluştu:", error);
        setUploadLoading(false);
      }
    } 
    
  }
  

  const fileHandle = async (e) => {
    let file = e.target.files[0];
    setFileRef(file);
    setStlFile(URL.createObjectURL(file));
  };

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

if(uploadLoading){
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="bg-white shadow-lg rounded-lg p-6 w-96">
        <h2 className="text-xl font-semibold text-center mb-4">Dosya Yükleniyor...</h2>
        
        <div className="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="absolute left-0 top-0 h-full bg-blue-500 transition-all duration-500"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
        
        <p className="text-center text-gray-700 mt-4">
          Yükleme İlerleme: <span className="font-semibold">{Math.round(uploadProgress)}%</span>
        </p>
      </div>
    </div>
  );

}else{

  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      
      <div className="flex justify-center items-center flex-grow">
        {(() => {
          if (asama === 0) {
            return (
              <div className="flex bg-white p-6 rounded-lg shadow-lg">
                <div>
                  <div className="flex items-center justify-start mb-6">
                  <img src={Logo} alt="Logo" className="w-28 h-10 mr-2" />

          
                  </div>
                  <h2 className="text-2xl font-semibold mb-4">Prototipleme</h2>
                  <p className="font-thin">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>

                  <div className="mb-4 mt-4">
                    <label
                      htmlFor="name"
                      className="block font-medium text-gray-700 mb-2"
                    >
                      Basım Teknolojisi:
                    </label>
                    <div className="flex flex-wrap mt-4">
                      {basimTeknolojisi.map((item) => (
                        <div key={item.id} className="w-1/2">
                          <div className="flex items-center text-gray-500">
                            <input
                              type="radio"
                              id={`item-${item.id}`}
                              checked={basim === item.id}
                              onChange={() => basimHandle(item.id)}
                              className="mr-2"
                            />
                            <label htmlFor={`item-${item.id}`}>
                              {item.label}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4 mt-4">
                    <label
                      htmlFor="name"
                      className="block font-medium text-gray-700 mb-2"
                    >
                      Malzeme Çeşidi:
                    </label>
                    <div className="flex flex-wrap mt-4">
                      {items.map((item) => (
                        <div key={item.id} className="w-1/2">
                          <div className="flex items-center text-gray-500">
                            <input
                              type="radio"
                              id={`item-${item.id}`}
                              checked={malzemeSecimi === item.id}
                              onChange={() => handleRadioChange(item.id)}
                              className="mr-2"
                            />
                            <label htmlFor={`item-${item.id}`}>
                              {item.label}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <label
                    htmlFor="name"
                    className="block font-medium text-gray-700 mb-2 mt-8"
                  >
                    Basım Miktarı:
                  </label>
                  <div className="flex items-center">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      value={adet}
                      onChange={handleChange}
                      className=" w-20 text-sm py-1 text-center border border-blue-500"
                      min="1"
                    />
                  </div>
                  <div className="mb-4 mt-5">
                    <label
                      htmlFor="file"
                      className="block font-medium text-gray-700 mb-2"
                    >
                      Dosya Yükle:
                      <span className="text-opacity-40 text-sm text-black">
                        (.stl)
                      </span>
                    </label>
                    <div className="flex items-center">
                      <label className="flex flex-col w-full h-48 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                        <div className="flex flex-col items-center justify-center pt-7">
                          {fileRef === null ? (
                            <>
                              <AiOutlineCloudUpload className="w-16 h-16"></AiOutlineCloudUpload>{" "}
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Dosya seç
                              </p>
                            </>
                          ) : (
                            <>
                              <AiFillFileAdd className="w-16 h-16"></AiFillFileAdd>{" "}
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                {fileRef.name}
                              </p>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          className="opacity-0"
                               accept=".stl"
                          onChange={fileHandle}
                        />
                      </label>
                    </div>
                  </div>
                  {(() => {
                    if (asama === 1) {
                      return uploadLoading === true ? (
                        <div className="block mt-10 items-center justify-center ">
                          <div
                            className=" top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
                            style={{
                              borderTop: "2px solid transparent",
                              borderBottom: "2px solid transparent",
                            }}
                          ></div>
                        </div>
                      ) : (
                        <button
                          onClick={async () => {
                            await prototipHizmetEkle();
                          }}
                          className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                        >
                          Gönder
                        </button>
                      );
                    } else {
                      return (
                        <div className="flex w-full justify-end">
                          <button
                            disabled={stlFile == null ? true : false}
                            onClick={() => {
                              setAsama(1);
                            }}
                            className={`px-12 py-2 mx-auto bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200 ${
                              stlFile == null ? "disabled" : ""
                            }`}
                          >
                            İleri
                          </button>{" "}
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            );
          } else {
            return (
              <div className="bg-white p-6 rounded-lg shadow-lg ">
                <div className="flex items-center justify-start mb-6 ">
                <img src={Logo} alt="Logo" className="w-28 h-10 mr-2" />

           
                </div>
                <AiOutlineArrowLeft
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="text-2xl"
                ></AiOutlineArrowLeft>
                <div className=" sm:block md:flex  ">
                  <div className="block items-center">
                    <StlViewer
                      className={`h-[50vh] sm:w-60vw md:w-40vw mx-5 `}
                      floorProps={{
                        gridWidth: 300,
                      }}
                      modelProps={{
                        positionX: 150,
                        positionY: 150,
                        ref: {
                          current: "[Circular]",
                        },
                        rotationX: 0,
                        rotationY: 0,
                        rotationZ: 0,
                        scale: 1,
                      }}
                      onError={function noRefCheck() {}}
                      onFinishLoading={onStlLoaded}
                      orbitControls
                      shadows
                      showAxes
                      url={stlFile}
                    />
                  </div>

                  <div className="flex flex-col mt-10 rounded-lg justify-center lg:mt-0 pl-5 md: w-96">
                    <div className="bg-gray-300 bg-opacity-50 p-5 ">
                      <h2 className="text-2xl font-semibold mb-4 text-black text-opacity-70 mt-6">
                        Talep Oluştur
                      </h2>

                      <div className="mb-4 mt-4">
                        <label
                          htmlFor="name"
                          className="block font-thin text-black text-2xl mb-2"
                        >
                          Baskı Teknolojisi:
                          <span className="text-xs font-extralight text-black text-opacity-40">
                            {basimTeknolojisi[basim].label}
                          </span>
                        </label>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-thin text-black text-opacity-80 mb-2"
                        >
                          Malzeme Çeşidi:{" "}
                          <span className="text-xs font-extralight text-black text-opacity-40">
                            {items[malzemeSecimi].label}
                          </span>
                        </label>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-thin text-black text-opacity-80 mb-2"
                        >
                          Boyut Bilgisi:
                          <span className="text-sm font-extralight text-black text-opacity-40">
                            {stlX + " x " + stlY + " x " + stlYukseklik + " mm"}
                          </span>
                        </label>
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block font-thin text-sm text-black text-opacity-80 mb-2"
                        >
                          Adet:
                          <span className="text-xs font-extralight text-black text-opacity-40">
                            {adet}
                          </span>
                        </label>
                      </div>
                      <div className="mb-4 mt-auto"></div>
                      {(() => {
                        if (asama === 1) {
                          return uploadLoading === true ? (
                            <div className="flex mx-auto w-full mt-10 items-center justify-center ">
                              <div
                                className="  top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
                                style={{
                                  borderTop: "2px solid transparent",
                                  borderBottom: "2px solid transparent",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <div className=" mt-10 mb-5 flex flex-row justify-evenly">
                              <button
                                onClick={() => {
                                  prototipHizmetEkle();
                                }}
                                className="px-12 py-2  bg-green-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                              >
                                Gönder
                              </button>
                              <button
                                onClick={() => {
                                  window.location.reload();
                                }}
                                className="px-12 py-2  bg-red-500  text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                              >
                                İptal
                              </button>
                            </div>
                          );
                        } else {
                          return (
                            <button
                              onClick={() => {
                                setAsama(1);
                              }}
                              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                            >
                              İleri
                            </button>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })()}
      </div>
      <NotificationContainer />
      <button
        className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center"
        onClick={() => {
       
          navigate("/");
        }}
      >
        <AiOutlineHome></AiOutlineHome>
      </button>
    </div>
  );
}
};

export default Prototipleme;

/*




   const prototipHizmetEkle = async () => {
        if (user !== null) {
            setUploadLoading(true);
            let userId = user.uid;
            const itemDocRef = doc(collection(fireDB, 'USERS'), userId);
            let fileName = fileRef.name;
            console.log(fileName);
            let fileId = uuidv4();

            const storageRef = ref(strogeDb, `${userId}/prototipleme/${fileId}`);

            try {
                await uploadBytes(storageRef, fileRef);

                const downloadURL = await getDownloadURL(storageRef);
                console.log(downloadURL);

                await updateDoc(itemDocRef, {
                    prototipHizmeti: arrayUnion({
                        "malzemeCesidi": items[malzemeSecimi].label,
                        "malzemeAdet": adet,
                        "baskiTeknolojisi": basimTeknolojisi[basim].label,
                        "boyutBilgisi": `${stlX} x ${stlY} x ${stlYukseklik} mm`,
                        "dosyaLink": downloadURL,
                        "dosyaIsmi": fileName,
                        "incelenmeDurumu": false,
                        "zaman": Date.now()
                    }),
                });
                NotificationManager.success('En kısa sürede dönüş yapılacaktır.', 'Ürün Eklendi', 2000, () => {
                    alert('Lütfen eposta ve şifreyi doğru giriniz.');
                });
                setFileDownloadLink(downloadURL);

                setFileRef(null);
                setBoyutBilgisi("");
                setMalzeme("");


                let interval = setTimeout(() => {
                    setUploadLoading(false);
                    navigate("/")
                }, 3000);

            } catch (error) {
                console.error("Hata oluştu:", error);
                setUploadLoading(false);
                // Hata durumunu kullanıcıya bildirebilirsiniz
            }
        }
    };

    */
