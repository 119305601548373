import React, { useState } from "react";

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

import { firebaseApp, fireDB } from "../../Config/FirebaseConfig";
import Login from "../../Assets/login.jpg";
import Logo from "../../Assets/newlogo.png";

import { useNavigate } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { collection, doc, setDoc } from "firebase/firestore";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const auth = getAuth(firebaseApp);

const backgroundStyle = {
  //backgroundImage: `url(${Login})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};
function SignUp() {
  let [isimVal, setIsimVal] = useState("");
  let [soyVal, setSoyVal] = useState("");
  let [firmaVal, setFirmaVal] = useState("");
  let [telVal, setTelVal] = useState("");
  let [mailVal, setMailVal] = useState("");
  let [sifreVal, setSifreVal] = useState("");
  let [loading, setLoading] = useState(false);

  const isimHandle = (e) => {
    setIsimVal(e.target.value);
  };

  const soyHandle = (e) => {
    setSoyVal(e.target.value);
  };

  const firmaHandle = (e) => {
    setFirmaVal(e.target.value);
  };

  const telHandle = (e) => {
    setTelVal(e.target.value);
  };

  const mailHandle = (e) => {
    setMailVal(e.target.value);
  };

  const sifreHandle = (e) => {
    setSifreVal(e.target.value);
  };

  const signUp = async () => {
    try {
      let user = await createUserWithEmailAndPassword(auth, mailVal, sifreVal);

      if (user.user !== null) {
        const userId = user.user.uid; // Buraya manuel olarak belirlemek istediğiniz doküman ID'sini yazın
        const usersCollectionRef = collection(fireDB, "USERS");
        const userDocRef = doc(usersCollectionRef, userId);
        await setDoc(userDocRef, {
          isim: isimVal,
          soyisim: soyVal,
          firma: firmaVal,
          telefon: telVal,
          eposta: mailVal,
          sifre: sifreVal,
          mesaj: false,
          taramaHizmeti: [],
          prototipHizmeti: [],
          osrHizmeti: [],
        });

        navigate("/");
      }

      setLoading(false);
    } catch (e) {
      NotificationManager.error(
        "Lütfen geçerli bir eposta girin.",
        "Hata Oluştu"
      );

      setLoading(false);
    }
  };

  let navigate = useNavigate();

  return (
<div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
  <div className="relative hidden sm:block" style={backgroundStyle}>
    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg flex flex-col items-center justify-center">
      <img src={Logo} alt="logo" className="w-60" />

      <div className="my-10 border-t h-px w-96"></div>
      <h1 className=" text-3xl mx-16 text-center font-extralight text-black">
        Parça üretimimiz, projelerin başarılı bir şekilde
        gerçekleştirilmesine giden yolunuzdur.
      </h1>
    </div>
  </div>

  <div className="bg-gray-200 flex flex-col justify-center">
    <div className="max-w-[400px] w-full mx-auto rounded-lg bg-[#0e2c66] p-8 px-8">
      <h2 className="text-4xl text-white font-bold text-center">Kayıt Ol</h2>

      <div className="flex flex-col text-gray-400 py-2">
        <label>İsim</label>
        <input
          value={isimVal}
          onChange={isimHandle}
          className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          type="text"
        />
      </div>

      <div className="flex flex-col text-gray-400 py-2">
        <label>Soyisim</label>
        <input
          value={soyVal}
          onChange={soyHandle}
          className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          type="text"
        />
      </div>

      <div className="flex flex-col text-gray-400 py-2">
        <label>Firma</label>
        <input
          value={firmaVal}
          onChange={firmaHandle}
          className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          type="text"
        />
      </div>

      <div className="flex flex-col text-gray-400 py-2">
        <label>Telefon</label>
        <input
          value={telVal}
          onChange={telHandle}
          className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          type="tel"
        />
      </div>

      <div className="flex flex-col text-gray-400 py-2">
        <label>Eposta</label>
        <input
          value={mailVal}
          onChange={mailHandle}
          className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          type="email"
        />
      </div>

      <div className="flex flex-col text-gray-400 py-2">
        <label>Şifre</label>
        <input
          value={sifreVal}
          onChange={sifreHandle}
          className="p-2 rounded-lg bg-gray-700 mt-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
          type="password"
        />
      </div>

      {loading ? (
        <div className="relative w-5 h-5 mx-auto py-4">
          <div
            className="absolute top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
            style={{
              borderTop: "2px solid transparent",
              borderBottom: "2px solid transparent",
            }}
          ></div>
        </div>
      ) : (
        <button
          onClick={async () => {
            if (
              isimVal.length > 3 &&
              soyVal.length > 2 &&
              firmaVal.length > 2 &&
              telVal.length > 8 &&
              mailVal.length > 5 &&
              sifreVal.length > 6
            ) {
              setLoading(true);
              await signUp();
            } else {
              setLoading(false);
              NotificationManager.error(
                "Lütfen bilgileri doğru ve eksiksiz doldurunuz.",
                "Eksik Bilgi"
              );
            }
          }}
          className="w-full my-5 py-2 bg-[#f5640c] shadow-lg shadow-orange-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
        >
          Kayıt Ol
        </button>
      )}

      <div className="text-center w-full">
        <button
          onClick={() => {
            navigate("/giris");
          }}
          className="text-gray-400 mt-5 text-sm"
        >
          Zaten bir hesabınız var mı?
        </button>
      </div>
    </div>
    <NotificationContainer />
    <button
      className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-[#0e2c66] text-white rounded-full shadow-lg flex items-center justify-center"
      onClick={() => {
        navigate("/");
      }}
    >
      <AiOutlineHome></AiOutlineHome>
    </button>
  </div>
</div>


  );
}

export default SignUp;

/*


   <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">

            <div className="relative hidden sm:block" style={backgroundStyle}>
                <div className="absolute top-0 left-0 w-full h-full bg-blue bg-opacity-50 backdrop-filter backdrop-blur-lg flex flex-col items-center justify-center">
                    <img src={Logo} alt="logo" className="w-32" />

                    <div className="my-10 border-t h-px w-96"></div>
                    <h1 className=" text-3xl mx-16 text-center font-extralight text-white">
                        Parça üretimimiz, projelerin başarılı bir şekilde gerçekleştirilmesine giden yolunuzdur.
                    </h1>

                </div>
            </div>

            <div className='bg-gray-800 flex flex-col justify-center'>
                <div className='max-w-[400px] w-full mx-auto rounded-lg bg-gray-900 p-8 px-8'>
                    <h2 className='text-4xl dark:text-white font-bold text-center'>Kayıt Ol</h2>
                    <div className='flex flex-col text-gray-400 py-2'>
                        <label>İsim</label>
                        <input value={isimVal} onChange={isimHandle} className='rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none' type="text" />
                    </div>

                    <div className='flex flex-col text-gray-400 py-2'>
                        <label>Soyisim</label>
                        <input value={soyVal} onChange={soyHandle} className='rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none' type="text" />
                    </div>

                    <div className='flex flex-col text-gray-400 py-2'>
                        <label>Firma</label>
                        <input value={firmaVal} onChange={firmaHandle} className='rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none' type="text" />
                    </div>

                    <div className='flex flex-col text-gray-400 py-2'>
                        <label>Telefon</label>
                        <input value={telVal} onChange={telHandle} className='rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none' type="text" />
                    </div>
                    <div className='flex flex-col text-gray-400 py-2'>
                        <label>Eposta</label>
                        <input value={mailVal} onChange={mailHandle} className='rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none' type="text" />
                    </div>

                    <div className='flex flex-col text-gray-400 py-2'>
                        <label>Şifre</label>
                        <input value={sifreVal} onChange={sifreHandle} className='p-2 rounded-lg bg-gray-700 mt-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none' type="password" />
                    </div>

                    <button onClick={() => {
                        setLoading(true);
                        signUp()
                    }} className='w-full my-5 py-2 bg-blue-500 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg'>Kayıt Ol</button>
                    <div className='text-center w-full'><button onClick={() => { navigate("/giris") }} className='text-gray-400 mt-5 text-sm'>Zaten bir hesabınız var mı?</button></div>
                </div>
                <button
                    className="absolute text-3xl bottom-4 right-4 w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center"
                    onClick={() => {

                        navigate("/")
                    }}
                >
                    <AiOutlineHome ></AiOutlineHome>
                </button>
            </div>
        </div>



*/
